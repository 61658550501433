exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-pages-contact-js": () => import("./../../../src/templates/pages/contact.js" /* webpackChunkName: "component---src-templates-pages-contact-js" */),
  "component---src-templates-pages-coworker-js": () => import("./../../../src/templates/pages/coworker.js" /* webpackChunkName: "component---src-templates-pages-coworker-js" */),
  "component---src-templates-pages-coworkers-js": () => import("./../../../src/templates/pages/coworkers.js" /* webpackChunkName: "component---src-templates-pages-coworkers-js" */),
  "component---src-templates-pages-index-js": () => import("./../../../src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-pages-privacy-js": () => import("./../../../src/templates/pages/privacy.js" /* webpackChunkName: "component---src-templates-pages-privacy-js" */),
  "component---src-templates-pages-work-area-js": () => import("./../../../src/templates/pages/work-area.js" /* webpackChunkName: "component---src-templates-pages-work-area-js" */)
}

